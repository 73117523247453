<div *ngIf="version" class="_version">{{version}}</div>
@if(ready && !reloading) {
    @switch (authService.user()?.type) {
        @case ('assistant') {
            <div class="app app--assistant">
                <app-top *ngIf="!configService.routeConfig()?.hideTop"/>
                <app-navigation *ngIf="!configService.routeConfig()?.hideNavigation"/>
                <app-global-notifications *ngIf="!configService.routeConfig()?.hideNavigation"/>
                <router-outlet/>
                <app-footer *ngIf="!configService.routeConfig()?.hideFooter"/>
            </div>
        }
        @case ('supervisor') {
            <div class="app app--supervisor">
                <app-top *ngIf="!configService.routeConfig()?.hideTop"/>
                <app-navigation *ngIf="!configService.routeConfig()?.hideNavigation"/>
                <app-global-notifications *ngIf="!configService.routeConfig()?.hideNavigation"/>
                <router-outlet/>
                <app-footer *ngIf="!configService.routeConfig()?.hideFooter"/>
            </div>
        }
        @case ('staff') {
            <div class="app app--staff">
                <app-top *ngIf="!configService.routeConfig()?.hideTop"/>
                <app-navigation *ngIf="!configService.routeConfig()?.hideNavigation"/>
                <app-global-notifications *ngIf="!configService.routeConfig()?.hideNavigation"/>
                <router-outlet/>
                <app-footer *ngIf="!configService.routeConfig()?.hideFooter"/>
            </div>
        }
        @case ('admin') {
            <div class="app app--admin">
                <app-top *ngIf="!configService.routeConfig()?.hideTop" [fullWidth]="true"/>
                <div class="admin-router-navigation-wrap">
                    <app-admin-navigation *ngIf="!configService.routeConfig()?.hideNavigation"/>
                    <router-outlet/>
                </div>
            </div>
        }
        @default {
            <div class="app app--public">
                <!-- public -->
                <app-top *ngIf="!configService.routeConfig()?.hideTop" [boxedWidth]="true"/>
                <router-outlet/>
                <!-- <app-footer *ngIf="!configService.routeConfig()?.hideFooter"/> -->
            </div>
        }
    }
}