import { Component, inject } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { RouterModule } from '@angular/router';
import { PrefixSubscriptionPipe, TranslatePipe } from '../../../../utils/pipes';
import { AvatarComponent } from '../../misc/avatar/avatar.component';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { LegacyLinkInnerDirective } from '../../../../utils/directives';

@Component({
    selector: 'app-admin-navigation',
    standalone: true,
    imports: [
        RouterModule,
        TranslatePipe,
        AvatarComponent,
        PrefixSubscriptionPipe,
        NgbPopover,
        CommonModule,
        LegacyLinkInnerDirective,
        NgbTooltip
    ],
    templateUrl: './admin-navigation.component.html',
    styleUrl: './admin-navigation.component.scss'
})
export class AdminNavigationComponent {
    authService = inject(AuthService);
    user = this.authService.user;

    switchingToAccountId: number;

    switchAccount($event: Event, id: number) {
        if (id === this.user().subscriptionId) {
            $event.preventDefault();
            return;
        }
        this.switchingToAccountId = id;
    }
}
