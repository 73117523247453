import { Injectable, inject, signal } from '@angular/core';
// import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {
    // injections
    // recaptchaV3Service = inject(ReCaptchaV3Service);

    // signals
    currentV2Token = signal<string>(null);
    currentV3Token = signal<string>(null);

    withCaptchaV3(): Promise<void> {
        return new Promise<void>((resolve) => {
            // this.recaptchaV3Service.execute('withCaptchaV3').subscribe((token) => {
            //     this.currentV3Token.set(token);
            //     resolve();
            // });
        });
    }
}
