import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';
import { LanguageService } from './language.service';
import { RouteConfig } from '../models/config.model';
import { constants } from '../../utils/constants';
import { ConfigService } from './config.service';
import { ResourceService } from './resource.service';
import { AuthService } from './auth.service';
import { TranslatePipe } from '../../utils/pipes';
import { Meta, Title } from '@angular/platform-browser';
import { Language } from '../models/common.model';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from './helpers.service';

@Injectable({
    providedIn: 'root'
})
export class RoutingService {
    router = inject(Router);
    languageService = inject(LanguageService);
    configService = inject(ConfigService);
    resourceService = inject(ResourceService);
    authService = inject(AuthService);
    translatePipe = inject(TranslatePipe);
    titleService = inject(Title);
    metaService = inject(Meta);

    firstRouterCycleDone = false;
    hasUserCredentialsTimeout: ReturnType<typeof setTimeout>;

    public init(): Promise<void> {
        return new Promise((resolve) => {
            this.router.events.subscribe((event) => {
                if (event instanceof RoutesRecognized) {
                    let route = event.state.root.firstChild;
                    if (route) {
                        while (route.firstChild) {
                            route = route.firstChild;
                        }
                        if (this.firstRouterCycleDone) {
                            this.handleRouteChange(route);
                        } else {
                            this.firstRouterCycleDone = true;
                            const routeConfig: RouteConfig = route.data?.config;
                            if (routeConfig?.public) {
                                this.initPublicRoute(route, route.params.language ?? null).then(() => resolve());
                            } else {
                                this.initPrivateRoute(route).then(() => resolve());
                            }
                        }
                    }
                }
            });
        });
    }

    private async initPublicRoute(route: ActivatedRouteSnapshot, language: Language): Promise<void> {
        const promises: Promise<void>[] = [];
        promises.push(this.languageService.init(language));
        return Promise.all(promises).then(() => {
            this.initResources(false);
            this.authService.user.set(null);
            // if (this.configService.serverConfig().portal === 'public' && window.location.pathname === '/') {
            //     this.router.navigate([`/${this.languageService.getLanguage()}`]);
            // }
            this.handleRouteChange(route);
        });
    }

    private async initPrivateRoute(route: ActivatedRouteSnapshot) {
        // const user = await this.authService.fetchUser();
        const user = this.authService.user();
        const promises: Promise<void>[] = [];
        promises.push(this.languageService.init(<Language>user.language));
        return Promise.all(promises).then(() => {
            this.initResources(true);
            this.handleRouteChange(route);
        });
    }

    private initResources(authenticated: boolean) {
        const languages = constants.languages.map((language) => {
            return { title: this.translatePipe.transform(`language_${language}`), value: language, code: language };
        });
        if (authenticated) {
            // authenticated resources
        }
        if (!authenticated) {
            // unauthenticated resources
        }
        // shared resources
        this.resourceService.addResource('language', languages);
        const yesno = [
            { title: this.translatePipe.transform(`misc_yes`), value: true, code: true },
            { title: this.translatePipe.transform(`misc_no`), value: false, code: false }
        ];
        this.resourceService.addResource('yesno', yesno);
        const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => {
            return {
                title: capitalizeFirstLetter(dayjs(`2000-${x.toString().padStart(2, '0')}-01`).format('MMMM')),
                value: x.toString()
            };
        });
        this.resourceService.addResource('month', month);
    }

    private handleRouteChange(route: ActivatedRouteSnapshot) {
        while (route.firstChild) {
            route = route.firstChild;
        }
        const routeConfig: RouteConfig = route.data?.config;

        const appTitle = this.translatePipe.transform('meta_title');
        const appDescription = this.translatePipe.transform('meta_description');
        const routeTitle: 'meta_title' | `meta_title_${string}` | 'async' | undefined = routeConfig?.title;

        // set title
        if (routeTitle !== 'async') {
            if (routeTitle) this.titleService.setTitle(`${this.translatePipe.transform(routeTitle)} | ${appTitle}`);
            if (!routeTitle) this.titleService.setTitle(`${appTitle}`);
        }

        // set meta description
        this.metaService.addTag({
            name: 'description',
            content: appDescription
        });

        // set robots
        this.metaService.addTag({
            name: 'robots',
            content: routeConfig?.robots ?? 'noindex'
        });

        // set route config
        this.configService.routeConfig?.set(routeConfig);

        // set show / hide captcha
        if (route?.data?.captcha) {
            document.body.classList.add('show-captcha');
        } else {
            document.body.classList.remove('show-captcha');
        }

        // handle hasUserCredentials redirect
        if (this.hasUserCredentialsTimeout) {
            clearTimeout(this.hasUserCredentialsTimeout);
        }
        if (route?.data?.config?.hasUserCredentials) {
            this.hasUserCredentialsTimeout = setTimeout(() => {
                window.location.href = 'login';
            }, 300000);
        }
    }
}
