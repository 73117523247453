<div class="app-footer">
    <div class="container">
        <div class="app-footer-inner">
            <a class="link" href="/{{urlLanguage()}}/terms-and-conditions" target="_blank">{{'footer_text_1' | translate}}</a>
                <span> - </span>
            <a class="link" href="/{{urlLanguage()}}/privacy-policy" target="_blank">{{'footer_text_2' | translate}}</a>
                <span> - </span>
            {{'footer_text_3' | translate}} <a class="link" href="mailto:{{'misc_helpdesk_email' | translate}}">{{'misc_helpdesk_email' | translate}}</a>
        </div>
    </div>
</div>