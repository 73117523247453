<!-- @legacy -->
@if (user().notification) {
    @switch (user().notification.type) {
        @case ('archived') {
            <div class="global-notification global-notification--gray">
                <div class="container">
                    <div class="global-notification-inner">
                        <div class="left">
                            <i></i>
                            <span>{{'globalnotifications_archived_descr' | translate}}</span>
                        </div>
                        <div class="right">
                            <a [routerLink]="'info/archived-subscription' | prefixSubscription" class="link"><div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'globalnotifications_archived_btn' | translate}}</div></a>
                        </div>
                    </div>
                </div>
            </div>
        }
        @case ('external-webshop') {
            <div class="global-notification global-notification--orange">
                <div class="container">
                    <div class="global-notification-inner">
                        <div class="left">
                            <i></i>
                            <span>{{'globalnotifications_external-webshop_descr' | translate}}</span>
                        </div>
                        <div class="right">
                            <a [routerLink]="'settings/billing' | prefixSubscription" class="app-button app-button--orange"><div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'globalnotifications_external-webshop_btn' | translate}}</div></a>
                        </div>
                    </div>
                </div>
            </div>
        }
        @case ('expired') {
            <div class="global-notification global-notification--red">
                <div class="container">
                    <div class="global-notification-inner">
                        <div class="left">
                            <i></i>
                            <span>{{'globalnotifications_expired_descr' | translate}}</span>
                        </div>
                        <div class="right">
                            <a [routerLink]="'billing' | prefixSubscription" class="app-button app-button--red"><div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'globalnotifications_expired_btn' | translate}}</div></a>
                        </div>
                    </div>
                </div>
            </div>
        }
        @case ('trial') {
            @switch (user().notification.severity) {
                @case ('high') {
                    <!-- trial high -->
                }
                @default {
                    <!-- trial not high -->
                }
            }
        }
        @case ('expiring') {
            @switch (user().notification.severity) {
                @case ('high') {
                    <div class="global-notification global-notification--orange">
                        <div class="container">
                            <div class="global-notification-inner">
                                <div class="left">
                                    <i></i>
                                    <span>{{'globalnotifications_expiring_high_descr_1' | translate}} {{user().notification.date | date : 'dd-MM-yyyy'}}{{'globalnotifications_expiring_high_descr_2' | translate}}</span>
                                </div>
                                <div class="right">
                                    <a [routerLink]="'billing' | prefixSubscription" class="app-button app-button--orange"><div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'globalnotifications_expiring_btn' | translate}}</div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                @default {
                    <div class="global-notification global-notification--green">
                        <div class="container">
                            <div class="global-notification-inner">
                                <div class="left">
                                    <i></i>
                                    <span>{{'globalnotifications_expiring_low_descr_1' | translate}} {{user().notification.days ?? 0}} {{'globalnotifications_expiring_low_descr_2' | translate}}</span>
                                </div>
                                <div class="right">
                                    <button class="underline" (click)="hideBillingNotification()">{{'globalnotifications_expiring_hide_btn' | translate}}</button>
                                    <a [routerLink]="'billing' | prefixSubscription" class="app-button app-button--green"><div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'globalnotifications_expiring_btn' | translate}}</div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
        }
        <!-- legacy -->
        @case ('trainingplan') {
            @if (user().notification.action === 'add') {
                <div class="legacy-notification legacy-notification--yellow">
                    <div class="container">
                        <div class="legacy-notification-inner">
                            <div class="left">
                                <i></i>
                                <span>{{'globalnotifications_trainingsplan' | translate}}</span>
                                <a [routerLink]="'opleiding/stageplan' | prefixSubscription"><div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'globalnotifications_trainingsplan_add_link' | translate}}</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            } @else if (user().notification.action === 'detail') {
                <div class="legacy-notification legacy-notification--yellow">
                    <div class="container">
                        <div class="legacy-notification-inner">
                            <div class="left">
                                <i></i>
                                <span>{{'globalnotifications_trainingsplan' | translate}}</span>
                                <a [routerLink]="'opleiding/stageplan' | prefixSubscription"><div class="legacy-link-inner" legacyLinkInner [legacy]="true">{{'globalnotifications_trainingsplan_detail_link' | translate}}</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            } @else {
                <div class="legacy-notification legacy-notification--yellow">
                    <div class="container">
                        <div class="legacy-notification-inner">
                            <div class="left">
                                <i></i>
                                <span>{{'globalnotifications_trainingsplan' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        }
    }
}