import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';
import { RecaptchaService } from '../services/recaptcha.service';
import { AppService } from '../services/app.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    // injections
    appService = inject(AppService);
    authService = inject(AuthService);
    languageService = inject(LanguageService);
    recaptchaService = inject(RecaptchaService);
    route = inject(ActivatedRoute);

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentV3Token = this.recaptchaService.currentV3Token();
        const currentV2Token = this.recaptchaService.currentV2Token();
        let clonedRequest = request.clone();
        if (currentV3Token) {
            clonedRequest = clonedRequest.clone({
                headers: clonedRequest.headers.append('captcha-token', currentV3Token)
            });
            this.recaptchaService.currentV3Token.set(null);
        }
        if (currentV2Token) {
            clonedRequest = clonedRequest.clone({
                headers: clonedRequest.headers.append('captcha-token-v2', currentV2Token)
            });
            this.recaptchaService.currentV2Token.set(null);
        }

        clonedRequest = clonedRequest.clone({
            headers: clonedRequest.headers.append('Accept-Language', this.languageService.getLanguage())
        });

        if (clonedRequest.method == 'GET') {
            const version = `${this.appService.getVersionNumber()}-${this.languageService.getLanguage()}`;
            clonedRequest = clonedRequest.clone({
                setParams: {
                    v: version
                }
            });
        }

        return next.handle(clonedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                let route = this.route.snapshot;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                switch (error.status) {
                    case 400:
                        // validation
                        break;
                    case 409:
                        // bad request
                        break;
                    case 401:
                        this.onUnauthorized();
                        break;
                    case 500:
                    case 502:
                    case 403:
                    case 429:
                    case 404:
                        this.showFailWhale(error);
                        break;
                }
                return throwError(() => error);
            })
        );
    }

    showFailWhale(error) {
        setTimeout(() => {
            const msg = `${error.error}`;
            document.querySelector('body').innerHTML = msg;
        }, 1);
    }

    onUnauthorized() {
        let route = this.route.snapshot;
        while (route.firstChild) {
            route = route.firstChild;
        }
        if (!route.data['public']) {
            this.authService.onUnauthorized();
        }
    }
}
