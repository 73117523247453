import { Component, inject } from '@angular/core';
import { TranslatePipe } from '../../../../utils/pipes';
import { LanguageService } from '../../../services/language.service';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [TranslatePipe],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {
    urlLanguage = inject(LanguageService).urlLanguage;
}
